import '@convincely/components/src/components/Funnel/popstate.js';
import {init} from '@convincely/core/src/bootstrap.js';
import {featureToggle} from '@convincely/core/src/feature-toggles.js';
import {conf} from '@convincely/utils/lib/services/conf.js';
import {convincely} from '@convincely/utils/lib/services/convincely.js';
import {urlParams} from '@convincely/utils/lib/url-params.js';
import {isCoreExtra} from '../src/core/_core-extra.js';
import {data} from '../src/core/conf-custom.mjs';
import {loadFunnel} from '../src/core/load.js';
import {fireWhenDOMReady} from '../src/core/utils.js';
import {injectScriptAndReady} from '../src/utils/scripts.js';
import {monitoringService} from '@convincely/utils/lib/services/monitoring';

let variantsReady;

if (
  !convincely.hasLoaded &&
  (conf?.preventDisable?.[conf.custom.funnel] || urlParams.cvly !== 'disabled') &&
  (!conf?.preventLoad?.[conf.custom.funnel] || urlParams.load === 'true')
) {
  convincely.hasLoaded = true;
  variantsReady = initVariant();
  fireWhenDOMReady(loadConvincely);
}

async function loadConvincely() {
  try {
    featureToggle(
      conf.$appName,
      undefined, undefined,
      'https://cvly.app/feature/:appName/:feature/',
      'https://staging.cvly.app/:appName/:feature/',
    );

    await variantsReady;
    const funnel = loadFunnel();

    const opts = {
      scripts: [
        ...funnel.js,
      ],
    };

    if (!funnel.delayAppInjection) {
      opts.scripts.push(`${conf.baseUrl}app-${conf.app}.js`);
    }

    if (!conf.isTest) {
      opts.styles = [
        ...funnel.css,
        `${conf.baseUrl}reset.css`,
        `${conf.baseUrl}styles.css`,
      ];
    }

    init(opts);

    if (conf.monitoring) {
      monitoringService.enable();

      if (conf.userId) {
        monitoringService.setUserId(conf.userId);
      }
    }
  } catch (e) {
    if (conf.isDev) {
      console.info('Convincely - Stopped execution', e);
    }

    monitoringService.trackError(e);
  }
}

async function initVariant() {
  let variant;
  if (isCoreExtra[data.funnel]) {
    await injectScriptAndReady(`${conf.baseUrl}core/${data.funnel}.js`);
    variant = convincely.core?.(data);
  }

  data.variant = urlParams.variant || urlParams.cvlyab || variant || data.variant || conf.defaultVariant;
}
